import React from 'react'
import Img from 'gatsby-image'

const BannerContent = props => {
  const image = props.image.childImageSharp.fluid

  return (
    <div className="content">
      <header>
        <h2>{props.title}</h2>
        <p>
          {props.text1}
          <br />
          {props.text2}
          <br />
          {props.text3}
        </p>
      </header>
      <span className="image">
        <Img
          className=""
          fluid={image}
          alt="Photo of Owner"
        />
      </span>
    </div>
  )
}

export default BannerContent
