import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

const Three = props => {
  const image = props.image.childImageSharp.fluid

  return (
    <section
      id="three"
      className="spotlight style3 left inactive"
      style={{ backgroundImage: `url(`+image.src+`)` }}
    >

      <span className="image fit main">
        <Img
          className=""
          fluid={image}
          alt="Group doing a stretching exercise"
        />
      </span>
      <Fade
        duration={250}
        left
        big
      >
        <div className="content">
        {/* Can't use Heading component due to lack of 'major' classname */}
        <header>
          <h2>How you benefit</h2>
          <p>Pilates offers many health benefits</p>
        </header>
          <div className="col-6 col-12-medium">
            <ul style={{textAlign: "left"}}>
              <li>improved flexibility</li>
              <li>increased muscle strength and tone, paticularly the core muscles
                  (abs, lower back, hips, and buttocks)
              </li>
              <li>balanced muscular strength on both sides of your body</li>
              <li>enhanced muscular control of your back and limbs</li>
              <li>improved stabilization of your spine</li>
              <li>rehabilitation or prevention of injuries related to muscle imbalances</li>
              <li>improved physical coordination and balance</li>
              <li>relaxation of your shoulders, neck, and upper back</li>
              <li>safe rehabilitation of joint and spinal injuries</li>
              <li>prevention of musculoskeletal injuries</li>
              <li>improved concentration</li>
              <li>stress management and relaxation</li>
            </ul>
          </div>
        </div>
      </Fade>
      <ScrollLink
        to="five"
        className="goto-next"
        activeClass="active"
        smooth={true}
        offset={50}
        duration={1500}
        spy={true}
      >
      Next
      </ScrollLink>
    </section>
  )
}

export default Three
