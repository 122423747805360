import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import BannerContent from '../components/Banner-Content'


const Banner = props => (
  <section id="banner">
    <BannerContent
      image={props.image}
      title="Plates is Here!"
      text1="Your Virtual Pilates Home"
      text2="Join our community to workout."
      text3="Improve wellness without leaving home."
    />
    <ScrollLink
    to="one"
    className="goto-next"
    activeClass="active"
    smooth={true}
    offset={50}
    duration={1500}
    spy={true}
    >
    Next
    </ScrollLink>
  </section>
)

export default Banner
