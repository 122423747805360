import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image'
import Heading from '../components/Heading'


const Two = props => {
  const image = props.image.childImageSharp.fluid

  return (
    <section
      id="two"
      className="spotlight style2 right inactive"
      style={{ backgroundImage: `url(`+image.src+`)` }}
    >

      <span className="image fit main">
        <Img
          className=""
          fluid={image}
          alt="Woman on a reformer with weights"
        />
      </span>
      <Fade
        duration={250}
        right
        big
      >
        <div className="content">
          <Heading
            title="Reform at Home Membership"
            text="Get the equipment from the studio to your home"
          />
          <p>
            Our Reform at Home membership is a monthly membership where we provide
            a reformer for you to use in virtual classes. In addition to the reformer
            rental, you will receive unlimited virtual classes, a weekly pre-recorded
            reformer session, and discounted private session rates. Reformer rentals
            are limited so reserve yours today!
          </p>
        </div>
      </Fade>
      <ScrollLink
        to="three"
        className="goto-next"
        activeClass="active"
        smooth={true}
        offset={50}
        duration={1500}
        spy={true}
      >
      Next
      </ScrollLink>
    </section>
  )
}

export default Two
