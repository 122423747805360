import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'
import Heading from '../components/Heading'


const One = (props) => {
  const image = props.image.childImageSharp.fluid

  return (
    <section
      id="one"
      className="spotlight style1 bottom inactive"
      style={{ backgroundImage: `url(`+image.src+`)` }}
    >
      <span className="image fit main">
        <Img
          className=""
          fluid={image}
          alt="Woman in Childs Pose"
        />
      </span>
      <Fade
        duration={250}
        bottom
        big
      >
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-4 col-12-medium">
                <Heading
                  title="Expanding Pilates"
                  text="Practice Pilates on your time in your home"
                />
              </div>
              <div className="col-4 col-12-medium">
                <p>
                  We offer "livestream" classes and training sessions taught in real-time.
                  You can grow and maintain your Pilates practice by simply turning on your laptop
                  or tablet. Each session is done through Zoom. The Zoom platform allows the
                  instructor and students to interact seamlessly.
                </p>
              </div>
              <div className="col-4 col-12-medium">
                <p>
                  Our group classes offer both virtual fitness and community building
                  as we laugh and sweat together. Mat classes have different themes and a focus on different props
                  allowing more variety in our classes. Group classes are a great way
                  to feed off of the energy of fellow students.
                </p>
                <p>
                  Our group reformer sessions will allow a maximum of 6 students to
                  allow for more personal feedback from your instructor. If you don't
                  have a reformer at home check out our "Reform at Home" monthly membership.
                </p>
                <p>
                  Virtual Private sessions are also available. Private instruction is
                  a great option for those who are new to Pilates, recovering/managing injury, and
                  who own additional Pilates equipment not covered in our class offerings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <ScrollLink
        to="two"
        className="goto-next"
        activeClass="active"
        smooth={true}
        offset={50}
        duration={1500}
        spy={true}
      >
      Next
      </ScrollLink>
    </section>
  )
}



export default One
