import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import One from '../components/One'
import Two from '../components/Two'
import Three from '../components/Three'
import Five from '../components/Five'


export const childsPose = graphql`
  query {
    banner: file(relativePath: { eq: "jess_face.jpg" }) {
      childImageSharp {
        fluid (quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bannerBG: file(relativePath: { eq: "DSC01108.jpg" }) {
      childImageSharp {
        fluid (quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    first: file(relativePath: { eq: "DSC01067.jpg" }) {
      childImageSharp {
        fluid (quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    second: file(relativePath: { eq: "DSC01163.jpg" }) {
      childImageSharp {
        fluid (quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    third: file(relativePath: { eq: "DSC01128.jpg" }) {
      childImageSharp {
        fluid (quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`


class Home extends React.Component {
  render() {

    return (
      <Layout location="/">
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title="Plates - Online Pilates"
          meta={[
            { name: 'description',
              content: 'Online virtual Pilates studio specializing in classes using foam rollers, gliders, reformers, and magic circles.' },
            { name: 'keywords', content: 'pilates, exercise, virtual, online, equipment, apparatus, reformer, tower, philadelphia' },
          ]}
        >
          <link rel="canonical" href="https://www.plates-pilates.com" />
        </Helmet>
        <Banner image={this.props.data.banner} />
        <One image={this.props.data.first} />
        <Two image={this.props.data.second} />
        <Three image={this.props.data.third} />
        {/* <Four /> */}
        <Five />
      </Layout>
    )
  }
}

export default Home
