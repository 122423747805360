import React from 'react'
import Fade from 'react-reveal/Fade'
import Heading from '../components/Heading'

const processForm = form => {
  const data = new FormData(form)
  let req = {}

  data.forEach(function(value, key) {
    req[key] = value
  })

  fetch('/.netlify/functions/send-mail', {
    method: 'POST',
    body: JSON.stringify(req)
  })
  .then( (response) => {
    console.log(response.body)
    response.ok ?
      form.innerHTML = '<div class="form--success">Thank you for your message</div>'
      : form.innerHTML = '<div class="form--error" style="background-color: red">Error: There was a problem, please try again later.</div>'
  })
  .catch(error => {
    form.innerHTML = '<div class="form--error">Error: ${error}</div>'
  })
}

class Five extends React.Component {
  constructor(props) {
    super(props)
    this.form = {
      elem: '',
      func: {}
    }
  }

  componentDidMount = (el) => {
    console.log("Component Mounted")
    this.form.elem = document.querySelector('.cta')
    this.form.func = (e) => {
      e.preventDefault()
      processForm(this.form.elem)
    }

    this.form.elem.addEventListener( 'submit', this.form.func )
  }

  componentWillUnmount = (el) => {
    this.form.elem.removeEventListener( 'submit', this.form.func )
  }

  render() {

    return (
      <section id="five" className="wrapper style2 special fade inactive">
        <Fade duration={500}>
          <div className="container">
          <Heading
            title="Something to Say?"
            text="Questions, Comments, Concerns, Life Dreams. Let Us Know!"
          />
            <form name="contact" method="POST" data-netlify="true" netlify-honeypot="botfield" className="cta">
            <div hidden aria-hidden="true">
              <label>
                Don’t fill this out if you're human:
                <input name="botfield" />
              </label>
            </div>
              <div className="row gtr-uniform gtr-50">
                <div className="col-12">
                  <input
                    aria-label="Your Name"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    minLength="3"
                    maxLength="50"
                    required
                />
                </div>
                <div className="col-12">
                  <textarea
                    aria-label="Your Message"
                    name="details"
                    id="message"
                    placeholder="Enter your message"
                    rows="6"
                    minLength="10"
                    maxLength="1000"
                    required
                  ></textarea>
                </div>
                <div className="col-8 col-12-xsmall">
                  <input
                    aria-label="Your email"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your Email Address"
                  />
                </div>
                <div className="col-4 col-12-xsmall">
                  <input
                    aria-label="Submit the form"
                    id="submitForm"
                    type="submit"
                    value="Contact"
                    className="fit primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </section>
    )
  }
}

export default Five
